var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{staticClass:"mx-2 pt-2"},[_c('v-col',[_c('h3',[_vm._v("Daftar Pelanggan")])]),_c('v-col',{staticClass:"ms-auto",attrs:{"cols":"auto"}},[_c('input-icon-toggle',{staticClass:"ms-2",attrs:{"icon":_vm.icons.mdiMagnify,"placeholder":"Cari pelanggan"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mx-2 mb-2"},[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4"}},[_c('v-select',{attrs:{"placeholder":"Sortir Berdasarkan","items":[],"item-text":"title","item-value":"value","outlined":"","dense":"","hide-details":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3"}},[_c('v-badge',{staticClass:"w-100 cursor-pointer",attrs:{"bordered":"","overlap":""},nativeOn:{"click":function($event){return _vm.clearFilter()}},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])]},proxy:true}])},[_c('v-btn',{attrs:{"outlined":"","block":"","large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = true}}},[_vm._v(" Filter (2) ")])],1)],1),_c('v-col',{staticClass:"ms-auto d-flex",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.viewTab),callback:function ($$v) {_vm.viewTab=$$v},expression:"viewTab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTable))])],1)]}}])},[_c('span',[_vm._v("Tampilan Tabel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCardText))])],1)]}}])},[_c('span',[_vm._v("Tampilan Kartu")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ms-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'customer-add' })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)]}}])},[_c('span',[_vm._v("Tambah Pelanggan")])])],1)],1),_c('v-tabs-items',{model:{value:(_vm.viewTab),callback:function ($$v) {_vm.viewTab=$$v},expression:"viewTab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.state.customers.value,"items-per-page":5,"item-key":"id","show-select":"","disable-sort":"","footer-props":{
          'items-per-page-options': [5, 10, 15],
          'items-per-page-text': 'Jumlah data',
        },"server-items-length":_vm.state.customers.value.length,"mobile-breakpoint":"0","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('router-link',{attrs:{"to":{ name: 'customer-detail', params: { id: props.item.id } }}},[_c('span',{staticClass:"primary--text font-medium"},[_vm._v(_vm._s(((props.item.first_name) + " " + (props.item.last_name))))])])]}},{key:"item.type",fn:function(props){return _vm._l((props.item.type),function(type){return _c('v-chip',{key:type.id,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(type.name)+" ")])})}},{key:"item.action",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}])},[_c('v-list',[_c('v-list-item',[_vm._v(" Lihat Detail ")])],1)],1)]},proxy:true},{key:"footer.page-text",fn:function(item){return [_vm._v(" Melihat "+_vm._s(item.pageStart)+" - "+_vm._s(item.pageStop)+" dari total "+_vm._s(item.itemsLength)+" data ")]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-tab-item',{staticClass:"px-5"},[_c('v-data-iterator',{attrs:{"headers":_vm.headers,"items":_vm.state.customers.value,"items-per-page":5,"item-key":"id","show-select":"","disable-sort":"","footer-props":{
          'items-per-page-options': [5, 10, 15],
          'items-per-page-text': 'Jumlah data',
        },"server-items-length":_vm.state.customers.value.length,"mobile-breakpoint":"0","loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticClass:"match-height"},_vm._l((props.items),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex flex-column pa-4",attrs:{"outlined":"","elevation":"1"},on:{"click":function($event){return _vm.$router.push({ name: 'customer-detail', params: { id: item.id } })}}},[_c('div',{staticClass:"d-flex mb-1 align-start"},[_c('v-avatar',{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"44"}},[_vm._v(" FI ")]),_c('div',[_c('span',{staticClass:"text-subtitle-2 font-weight-bold d-block primary--text"},[_vm._v(_vm._s(((item.first_name) + " " + (item.last_name))))]),_c('span',{staticClass:"text-caption font-medium"},[_vm._v(_vm._s(item.phone_number))])]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto mt-2",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_vm._v(" Lihat Detail ")])],1)],1)],1),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(item.email))]),_c('div',{staticClass:"d-flex flex-wrap mt-2"},_vm._l((5),function(i){return _c('v-chip',{key:i,staticClass:"mr-1 mb-1",attrs:{"x-small":""}},[_vm._v(" Tag Customer "+_vm._s(i)+" ")])}),1)])]}}],null,true)})],1)}),1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Filter Pelanggan ")]),_c('v-card-text',[_vm._v(" Hello this is filter modal. The content is coming up soon. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }